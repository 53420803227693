import { Suspense, lazy, useEffect } from 'react';

import Image from 'next/image';
import dynamic from 'next/dynamic';

import {
  AppBanner,
  Container,
  Row,
  Col,
  Button,
  Paragraph,
  Banner,
  Footer,
  Breadcrumb,
} from 'darrius';

import { useCategoryDialogs } from '../../context/CategoryDialogsContext/CategoryDialogsContext';

import { getAppBannerLink } from '../../utils/getAppBannerLink';
import { getFlashTitle } from '../../utils/getFlashTitle';

import {
  HeadContent,
  CategoryForm,
  CategoryRating,
  AveragePrice,
  SimilarRequest,
  Faq,
  LinkList,
  Badges,
  Dialog,
  Loading,
  Header,
} from '../../components';

import styles from './CategoryTemplate.module.scss';

import { type IBreadcrumb } from '../../modules/shared/@interfaces';
import { type IFormData } from '../../modules/shared/pageProps/formData/@interfaces';
import { type IRatingData } from '../../modules/budget/pageProps/ratings/@interfaces';
import { type IHeaderContent } from '../../locales/@interfaces/shared/headerContent';
import { type IFooterContent } from '../../locales/@interfaces/shared/footerContent';
import { type IUserCliDataResponse } from '../../modules/user';
import { triggerEvent } from '../../utils';

export type TMainSectionData = {
  title: string;
  subtitle: string;
  image: {
    src: string;
    alt: string;
  };
  breadcrumbs: {
    items: IBreadcrumb[];
    structuredData: string;
  };
};

type TLinkCtaPro = {
  text: string;
  url: string;
};

type TAveragePrices = {
  average: string;
  min: string;
  max: string;
};

type TAveragePricesItem = {
  id: number;
  service: string;
  link: string;
  prices: TAveragePrices;
}[];

type TSimilarRequestsItems = {
  id: number;
  cliName: string;
  profession: string;
  text: string;
  link: string;
};

type TFaqItems = {
  id: number;
  question: string;
  answer: string;
};

type TRelatedLinks = {
  id: number;
  text: string;
  url: string;
};

export interface ICategoryTemplateProps {
  isGeolocated: boolean;
  categorySlug: string;
  meta: {
    title: string;
    description: string;
    canonical: string;
    keywords: string;
    image: string;
  };
  headerContent: IHeaderContent;
  mainSection: TMainSectionData;
  formData: IFormData;
  ratings: IRatingData;
  ctaPro?: {
    title: string;
    subtitle: string;
    link: TLinkCtaPro;
  };
  averagePrices?: {
    title: string;
    subtitle: string;
    items: TAveragePricesItem;
  };
  similarRequests?: {
    title: string;
    subtitle: string;
    items: TSimilarRequestsItems[];
  };
  faq?: {
    title: string;
    items: TFaqItems[];
    structuredData: string;
  };
  relatedLinks: {
    id: number;
    title: string;
    links: TRelatedLinks[];
  }[];
  footerContent: IFooterContent;
  userData: IUserCliDataResponse;
}

const HowGnWorksDialog = lazy(
  () => import('./HowGnWorksDialog/HowGnWorksDialog')
);

const InteractionDialog = dynamic(
  () => import('./InteractionDialog/InteractionDialog'),
  { ssr: false }
);

export const CategoryTemplate = (props: ICategoryTemplateProps) => {
  const {
    meta,
    headerContent,
    categorySlug,
    mainSection,
    formData,
    ratings,
    ctaPro,
    averagePrices,
    similarRequests,
    faq,
    relatedLinks,
    footerContent,
    isGeolocated,
    userData,
  } = props;

  const {
    dialogHowGetNinjasWorksIsNotOpen,
    shouldShowHowGetNinjasWorksDialog,
    toggleDialog,
  } = useCategoryDialogs();

  const flashTitle = getFlashTitle(userData.notificationCount);

  useEffect(() => {
    const trackingTimeout = setTimeout(() => {
      triggerEvent('chat__nav-message', {
        userId: userData?.id,
      });
    }, 5000);
    return () => clearTimeout(trackingTimeout);
  }, [userData]);

  const bannerLink = isGeolocated
    ? getAppBannerLink('CATEGORY_GEOLOC')
    : getAppBannerLink('CATEGORY');

  return (
    <>
      <AppBanner link={bannerLink} />

      <HeadContent
        flashTitle={flashTitle}
        metaTitle={meta.title}
        metaDescription={meta.description}
        keywords={meta.keywords}
        canonicalLink={meta.canonical}
        pageType="categories:show"
        slug={categorySlug}
      />

      <Header.Auth userData={userData} header={headerContent} />

      <main
        className={`${styles['category-page']} category-page`}
        data-category-slug={categorySlug}
      >
        <Container className={`${styles['main-section']} main-section`}>
          <Row className="bottom-sm">
            <Col
              className={`${styles['main-section__title-area']} col-sm-12 col-md-8 main-section__title-area`}
            >
              <Breadcrumb
                breadcrumbs={mainSection.breadcrumbs.items}
                className="hide-sm"
              />

              <script
                id="category-configs"
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: mainSection.breadcrumbs.structuredData,
                }}
              />

              <Paragraph
                title={mainSection.title}
                className={`${styles['main-section__title']} main-section__title`}
              />

              <Paragraph
                text={mainSection.subtitle}
                className={`${styles['main-section__subtitle']} main-section__subtitle hide-sm`}
              />
            </Col>

            <Dialog.Root onOpenChange={() => toggleDialog('howGetNinjasWorks')}>
              <Col className="col-sm-8">
                <Badges showYoutubeLink />
              </Col>

              {shouldShowHowGetNinjasWorksDialog && (
                <Suspense fallback={<Loading />}>
                  <HowGnWorksDialog />
                </Suspense>
              )}
            </Dialog.Root>

            <Col
              className={`${styles['main-section__image']} col-sm-4 main-section__image`}
            >
              <Image
                src={mainSection.image.src}
                alt={mainSection.image.alt}
                width={270}
                height={360}
                className={styles['max-width-full']}
                priority
              />
            </Col>
          </Row>
        </Container>

        <Banner className={`${styles['form-and-ratings']} form-and-ratings`}>
          <Row className="space-between">
            <Col
              className={`${styles['form-area']} form-area col-sm-12 col-md-8`}
            >
              <CategoryForm {...formData} />
            </Col>

            <Col
              className={`${styles.ratings} ratings col-sm-12 col-md-4 center-sm`}
            >
              <CategoryRating {...ratings} />
            </Col>
          </Row>
        </Banner>

        {ctaPro && (
          <Banner className="cta-pro center-md">
            <Paragraph title={ctaPro.title} text={ctaPro.subtitle} size="2" />

            <Row className="center-md">
              <Col className="col-sm-12 col-md-5">
                <Button.Link href={ctaPro.link.url}>
                  {ctaPro.link.text}
                </Button.Link>
              </Col>
            </Row>
          </Banner>
        )}

        {averagePrices && (
          <Banner className="average-prices">
            <Paragraph
              title={averagePrices.title}
              text={averagePrices.subtitle}
              size="2"
              headline
            />

            <Row className="space-between center-sm slider-sm">
              {averagePrices.items.map((price) => (
                <Col key={price.id} className="col-sm-12 col-md-4 col-lg-3">
                  <AveragePrice
                    link={price.link}
                    service={price.service}
                    minimum={price.prices.min}
                    average={price.prices.average}
                    maximum={price.prices.max}
                  />
                </Col>
              ))}
            </Row>
          </Banner>
        )}

        {similarRequests && (
          <Banner className="similar-requests">
            <Paragraph
              title={similarRequests.title}
              text={similarRequests.subtitle}
              size="2"
              headline
            />

            <Row className="space-between center-sm slider-sm">
              {similarRequests.items.map((request) => (
                <Col
                  key={request.id}
                  className="col-sm-12 col-md-4 col-lg-3 start-sm"
                >
                  <SimilarRequest {...request} />
                </Col>
              ))}
            </Row>
          </Banner>
        )}

        {faq && <Faq {...faq} />}

        {relatedLinks.map((section) => (
          <LinkList
            key={section.id}
            title={section.title}
            links={section.links}
          />
        ))}
      </main>

      <Footer data={footerContent} />

      {dialogHowGetNinjasWorksIsNotOpen && <InteractionDialog />}
    </>
  );
};
