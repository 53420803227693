import Image from 'next/image';

import { Button, Col, Container, Paragraph, Row } from 'darrius';

import { imgPrefix } from '../../../../utils';
import { type IEmptyOrders } from '../../../../locales/@interfaces/orders/emptyOrders';

interface IEmptyOrdersProps {
  i18n: {
    emptyOrders: IEmptyOrders;
  };
}

export function EmptyOrders({ i18n }: IEmptyOrdersProps) {
  return (
    <Container>
      <Row className="center-md center-sm">
        <Col className="col-sm-12 col-md-12">
          <Image
            src={imgPrefix(i18n.emptyOrders.image)}
            alt=""
            width={i18n.emptyOrders.image.width}
            height={i18n.emptyOrders.image.height}
            quality={100}
          />

          <Paragraph
            title={i18n.emptyOrders.title}
            text={i18n.emptyOrders.description}
            size="3"
            headline
          />
        </Col>

        <Col className="col-sm-12 col-md-4">
          <Button.Link href={i18n.emptyOrders.cta.href} fullWidth>
            {i18n.emptyOrders.cta.label}
          </Button.Link>
        </Col>
      </Row>
    </Container>
  );
}
