import Image from 'next/image';

import { Avatar, Button, Col, Row, Stars, Tag } from 'darrius';

import { FlatCard, FlatCardBody } from '../../../components';

import { getBlurDataURL, imgPrefix, triggerEvent } from '../../../utils';

import { type TPro } from '../../../modules/instantMatch/transformations';

import styles from './InstitutionalPros.module.scss';

type IInstitutionalProProps = Omit<TPro, 'isInstitutionalPro' | 'tag'>;

export const InstitutionalPros = (props: IInstitutionalProProps) => {
  const {
    id,
    link,
    name,
    photo,
    rating,
    isVerifiedPro,
    servicePrice,
    requestId,
  } = props;
  return (
    <Col key={id} className={`col-sm-12 ${styles['pro-card']}`}>
      <FlatCard>
        <FlatCardBody>
          <div className="start-sm">
            <Row className={styles['pro-card__bio-section']}>
              <Col className="col-8">
                <div className={styles['pro-card__bio']}>
                  <Avatar
                    src={photo}
                    alt={name}
                    size={72}
                    fallback={{
                      name: name,
                      variant: 'byName',
                    }}
                  />

                  <div className={styles['pro-card__bio-info']}>
                    {isVerifiedPro ? (
                      <h3 className={styles['pro-card__name']}>
                        <span
                          className={styles['pro-card__name-nowrap']}
                        >{`${name}`}</span>
                      </h3>
                    ) : (
                      <h3>
                        <span
                          className={styles['pro-card__name-nowrap']}
                        >{`${name}`}</span>
                      </h3>
                    )}
                    <div className={styles['pro-card__tag']}>
                      <div className={styles.institutional__tag_specialist}>
                        <Tag className="action">Especialista</Tag>
                      </div>

                      <div>
                        <Tag className="action">Pagamento Seguro</Tag>
                      </div>
                    </div>
                    <div
                      className={`${styles['pro-card__rating']} ${styles.rating__default}`}
                    >
                      <Stars rating={1} />

                      <p>{rating.value}</p>
                      <p
                        className={`${styles['pro-card__amount-rating']}`}
                      >{`(${rating.count})`}</p>
                    </div>
                  </div>
                </div>
              </Col>

              <Col
                className={`${styles['pro-card__price']} col-sm-12 col-md-4`}
              >
                {servicePrice ? (
                  <>
                    <Col
                      className={`${styles['pro-card__price-text']} col-sm-5 col-md-12`}
                    >
                      <div className={styles['pro-card__price-title']}>
                        <p>A partir de:</p>
                      </div>
                      <div className={styles['pro-card__price-value']}>
                        <p>{servicePrice}</p>
                      </div>
                    </Col>
                    <Col
                      className={`${styles['pro-card__button']} col-sm-7 col-md-12`}
                    >
                      <div>
                        <Button.Link
                          className={`${styles['pro-card__indications-button']}`}
                          size="small"
                          variation="primary"
                          href={link}
                          onClick={() =>
                            triggerEvent(
                              'instant-match-profile-list__click-institutional-profile',
                              {
                                profile: id,
                                requestId,
                                price: servicePrice,
                              }
                            )
                          }
                          fullWidth
                        >
                          Ver indicação
                        </Button.Link>
                      </div>
                    </Col>
                  </>
                ) : (
                  <Col className={`${styles['pro-card__button']} col-sm-12`}>
                    <div>
                      <Button.Link
                        className={`${styles['pro-card__indications-button']}`}
                        size="small"
                        variation="primary"
                        href={link}
                        onClick={() =>
                          triggerEvent(
                            'instant-match-profile-list__click-institutional-profile',
                            {
                              profile: id,
                              requestId,
                            }
                          )
                        }
                        fullWidth
                      >
                        Ver indicação
                      </Button.Link>
                    </div>
                  </Col>
                )}
              </Col>
            </Row>
          </div>
          <hr className={styles.line}></hr>

          <Row className={`${styles['pro-card__status']}`}>
            <Col className={`${styles['pro-card__online']} col-sm-5`}>
              <span className={styles['pro-card__online-img']}>
                <Image
                  src={imgPrefix(
                    '/images/contato-instantaneo/img_instant-matching-online.svg'
                  )}
                  width="18"
                  height="18"
                  alt="Online agora"
                  placeholder="blur"
                  blurDataURL={getBlurDataURL()}
                  priority
                />
              </span>
              <span>Online agora</span>
            </Col>
            <Col className={`${styles['pro-card__answer-time']} col-sm-7`}>
              <span>Responde em cerca de 1h</span>
            </Col>
          </Row>
        </FlatCardBody>
      </FlatCard>
    </Col>
  );
};
