export function onlyNumbers(value: string): string {
  return value.replace(/\D/g, '');
}

export function phoneMask(phoneNumber: string): string {
  const numbers = onlyNumbers(phoneNumber);
  const chars = numbers.length;
  const ddd = chars > 2 ? `${numbers.slice(0, 2)} ` : numbers.slice(0, 2);
  const firstPart = `${numbers.slice(2, 7)}`;
  const lastPart = `-${numbers.slice(7, 11)}`;
  const fullMask = ddd + firstPart + lastPart;

  if (chars <= 7) {
    return ddd + firstPart;
  }

  return fullMask;
}
