/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-danger */
import { useEffect } from 'react';

import Image from 'next/image';
import Link from 'next/link';

import {
  Container,
  Footer,
  Row,
  Col,
  Banner,
  List,
  ListItem,
  Paragraph,
  Icon,
  Card,
  CardHeader,
  CardBody,
  TextLink,
  Stars,
  Button,
  Quote,
  StoreButton,
} from 'darrius';

import { useClientUser } from '../../hooks/useClientUser';

import { getBlurDataURL, triggerEvent } from '../../utils';
import { getFlashTitle } from '../../utils/getFlashTitle';

import {
  Header,
  HeadContent,
  ServicesCategories,
  FloatButton,
  MainServices,
  OverflowElement,
  CategoryCard,
  Carousel,
  KeepInPage,
  ChipLink,
} from '../../components';

import HomeRegister from './HomeRegister';

import { SearchCategory } from './components/SearchCategory';

import { type IHomePageProps } from '../../modules/home/pageProps/@interfaces';

import styles from './HomeTemplate.module.scss';

export const HomeTemplate = (props: IHomePageProps) => {
  const {
    config,
    headerContent,
    heroSection,
    servicesCategories,
    mainServices,
    bannerFull,
    recommendations,
    categoryCards,
    whatIsGetNinjas,
    frequentRequests,
    statistics,
    register,
    howItWorks,
    tipsBeforeHiring,
    about,
    ourValues,
    bottomLinks,
    footerContent,
    structuredData,
  } = props;

  const { user, isAuthenticated, userNotificationsCount } = useClientUser();

  useEffect(() => {
    const trackingTimeout = setTimeout(() => {
      triggerEvent('chat__nav-message', {
        userId: user?.id,
      });
    }, 5000);

    return () => clearTimeout(trackingTimeout);
  }, [user]);

  const flashTitle = getFlashTitle(userNotificationsCount);

  const userData = {
    ...user,
    isAuthenticated,
    notificationCount: userNotificationsCount,
  };

  return (
    <>
      <FloatButton />

      <HeadContent
        flashTitle={flashTitle}
        metaTitle={config.metaTitle}
        metaDescription={config.metaDescription}
        canonicalLink={config.canonicalURL}
        pageType="pages:home"
      />

      <main className={`${styles['main-section']} main-section`}>
        <Header.Auth userData={userData} header={headerContent} />

        <Container>
          <Button.Link
            href="/anuncie"
            className={`${styles['profissional-button']} primary hide-md profissional-button`}
            fullWidth
          >
            Seja um profissional
            <Button.Icon name="MdOutlineArrowForward" />
          </Button.Link>
        </Container>

        <Container as="section">
          <Row
            className={`${styles['hero-section']} hero-section bottom-sm middle-md`}
          >
            <Col className="col-sm-7 col-md-7">
              <Row className="hero-section__title-area">
                <Col className="col-sm-12">
                  <h1
                    className={`${styles['hero-section__title']} hero-section__title`}
                  >
                    {heroSection.title}
                  </h1>
                </Col>

                <Col className="col-sm-12">
                  <p
                    className={`${styles['hero-section__subtitle']} hero-section__subtitle`}
                  >
                    {heroSection.subtitle}
                  </p>
                </Col>
              </Row>
            </Col>

            <Col
              className={`${styles['hero-section__image']} hero-section__image col-sm-5 col-md-5 bottom-sm center-md`}
            >
              <Image
                alt={heroSection.pro.alt}
                src={heroSection.pro.img}
                width={268}
                height={340}
                placeholder="blur"
                blurDataURL={getBlurDataURL()}
                priority
                className="responsive-image"
              />
            </Col>

            <Col className="col-sm-12 col-md-12 hero-section__searchbar">
              <SearchCategory />
            </Col>

            <Col
              className={`col-sm-12 col-md-12 hero-section__main-services ${styles['hero-section__main-services']}`}
            >
              <p
                className={`main-services_title ${styles['main-services_title']}`}
              >
                <strong>Populares:</strong>
              </p>

              {heroSection.mainServices.map((service) => (
                <ChipLink
                  id={service.id}
                  text={service.text}
                  link={service.url}
                  target="_self"
                  key={service.id}
                />
              ))}
            </Col>
          </Row>

          <ServicesCategories
            data={servicesCategories}
            floating={config.floatingServicesCategories}
            floatingStart={200}
            className="first-md"
          />
        </Container>

        <MainServices data={mainServices} />

        <KeepInPage text="Buscar por serviços" link="#search" />

        <Banner
          as="section"
          className={`${styles['banner-full']} overflow-visible banner-full space-bottom yellow`}
        >
          <Row className="middle-sm space-top space-between">
            <Col className="col-sm-12 col-md-4 center-sm start-md">
              <Paragraph
                title={bannerFull.title}
                text={bannerFull.subtitle}
                size="2"
              />

              <List
                className={`${styles['download-buttons']} download-buttons`}
              >
                {bannerFull.appDownload.map(({ store, url }) => (
                  <ListItem key={store}>
                    <StoreButton store={store} href={url} />
                  </ListItem>
                ))}
              </List>
            </Col>

            <Col className="col-sm-12 col-md-8 center-sm">
              <OverflowElement
                className="bottom-sm top-md"
                ammount={{
                  sm: 6,
                  md: 10,
                  lg: 10,
                }}
              >
                <Image
                  src={bannerFull.img.src}
                  alt={bannerFull.img.alt}
                  width={491}
                  height={654}
                  placeholder="blur"
                  blurDataURL={getBlurDataURL()}
                  className={`${styles['responsive-download-image']} responsive-download-image`}
                />
              </OverflowElement>
            </Col>
          </Row>
        </Banner>

        <Banner as="section">
          <Container className="recommendations">
            <Paragraph
              size="2"
              title={recommendations.title}
              text={recommendations.subtitle}
              headline
              className="space-top"
            />

            <Row className="space-top space-bottom space-between">
              {recommendations.items.map((recommendation) => (
                <Col className="col-sm-12 col-md-4" key={recommendation.id}>
                  <Quote
                    text={recommendation.text}
                    title={recommendation.service}
                    subTitle={recommendation.user}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </Banner>

        <Container as="section">
          <CategoryCard data={categoryCards[0]} />
        </Container>

        <Banner as="section">
          <section id="what-is-getninjas">
            <Paragraph
              size="2"
              title={whatIsGetNinjas.title}
              text={whatIsGetNinjas.subtitle}
              headline
            />

            <Row className="space-between center-sm space-top">
              {whatIsGetNinjas.items.map((item) => (
                <Col className="col-sm-12 col-md-4" key={item.id}>
                  <Icon src={item.img} alt={item.alt} size={106} />

                  <Paragraph size="3" title={item.title} text={item.text} />
                </Col>
              ))}
            </Row>
          </section>
        </Banner>

        <Container as="section">
          <CategoryCard data={categoryCards[1]} />
        </Container>

        {frequentRequests && (
          <Banner as="section" className="frequent-requests full space-top">
            <Paragraph
              size="2"
              title={frequentRequests.title}
              text={frequentRequests.subtitle}
              headline
            />

            {frequentRequests.items && (
              <Carousel className="space-top">
                {frequentRequests.items.map((request) => (
                  <a href={request.link.url} key={request.id}>
                    <Card>
                      <CardHeader className="small">
                        <Icon
                          src={request.icon.src}
                          alt={request.icon.alt}
                          size={58}
                        />

                        <span className="text-link">{request.link.text}</span>
                      </CardHeader>

                      <CardBody className="small">
                        <Paragraph
                          size="3"
                          title={request.category.name}
                          text={request.category.description}
                        />

                        <Stars showText rating={request.rating} />

                        <Paragraph text={request.date} size="4" />
                      </CardBody>
                    </Card>
                  </a>
                ))}
              </Carousel>
            )}
          </Banner>
        )}

        <Container as="section">
          <Row className="statistics space-top space-bottom center-sm start-sm space-between">
            {statistics.items.map(({ value, text }) => (
              <Col className="col-sm-12 col-md-4" key={value}>
                <Paragraph
                  className={`${styles['statistics-statistic__title']} statistics-statistic__title`}
                  text={value}
                  size="2"
                  highlight
                />
                <Paragraph text={text} size="2" />
              </Col>
            ))}

            {statistics.note && <Paragraph text={statistics.note} size="4" />}
          </Row>
        </Container>

        <Container as="section">
          <HomeRegister data={register} />
        </Container>

        <Container as="section">
          <Row className="how-it-works space-top">
            <Col className="col-sm-12 center-sm middle-sm">
              <Paragraph size="2" title={howItWorks.title} />
            </Col>
          </Row>

          <Row className="space-between center-sm space-bottom">
            {howItWorks.items.map((item) => (
              <Col className="col-sm-12 col-md-3" key={item.id}>
                <Icon src={item.img} alt={item.alt} size={106} />

                <Paragraph size="4" text={item.text} />
              </Col>
            ))}
          </Row>
        </Container>

        {tipsBeforeHiring && (
          <Container as="section">
            <Paragraph
              size="2"
              title={tipsBeforeHiring.title}
              text={tipsBeforeHiring.subtitle}
              headline
            />

            <Carousel className="tips-before-hiring space-top">
              {tipsBeforeHiring.items.map((tip) => (
                <Card key={tip.title} className="tip">
                  <Image
                    src={tip.img.src}
                    alt={tip.img.alt}
                    width={316}
                    height={210}
                    placeholder="blur"
                    blurDataURL={getBlurDataURL()}
                    className="responsive-image"
                  />

                  <CardBody className="small">
                    <Paragraph size="3" title={tip.title} text={tip.text} />

                    <TextLink
                      url={tip.link}
                      text={tipsBeforeHiring.linkText}
                      target="_blank"
                    />
                  </CardBody>
                </Card>
              ))}
            </Carousel>
          </Container>
        )}

        {about && (
          <Container as="section">
            <Row className="about-us space-top space-bottom around-sm space-between">
              <Col className="col-md-5 col-sm-12 start-md center-sm">
                <Paragraph size="2" title={about.title} />

                {about.paragraphs.map(({ id, text }) => (
                  <Paragraph size="4" text={text} key={id} />
                ))}

                <Row className="space-top">
                  <Col className="col-sm-12 col-lg-8">
                    <Link href={about.link.url} passHref legacyBehavior>
                      <Button.Link fullWidth>{about.link.text}</Button.Link>
                    </Link>
                  </Col>
                </Row>
              </Col>

              <Col className="col-md-6 col-sm-12 center-sm first-sm">
                <Image
                  className={`${styles['about-image']} about-image responsive-image`}
                  src={about.img.src}
                  alt={about.img.alt}
                  width={541}
                  height={367}
                  placeholder="blur"
                  blurDataURL={getBlurDataURL()}
                />
              </Col>
            </Row>
          </Container>
        )}

        {ourValues && (
          <Container as="section">
            <Banner
              className={`${styles['our-values']} overflow-visible our-values space-top space-bottom`}
            >
              <Row className="space-between around-sm">
                <Col className="col-sm-12 col-md-2">
                  <OverflowElement
                    className="left-md left-lg"
                    ammount={{
                      md: 20,
                      lg: 20,
                      sm: 20,
                    }}
                  >
                    <Image
                      src={ourValues.img.src}
                      alt={ourValues.img.alt}
                      width={372}
                      height={262}
                      placeholder="blur"
                      blurDataURL={getBlurDataURL()}
                      className="responsive-image"
                    />
                  </OverflowElement>
                </Col>

                <Col className="col-sm-12 col-md-10">
                  <Paragraph
                    className="no-space"
                    title={ourValues.title}
                    size="2"
                  />

                  <List>
                    {ourValues.items.map((item) => (
                      <ListItem key={item.text}>{item.text}</ListItem>
                    ))}
                  </List>

                  <Row className="space-top">
                    <Col className="col-sm-12 col-md-9">
                      <Link href={ourValues.link.url} passHref legacyBehavior>
                        <Button.Link fullWidth>
                          {ourValues.link.text}
                        </Button.Link>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Banner>
          </Container>
        )}

        <Banner
          as="section"
          className={`${styles['bottom-links']} bottom-links`}
        >
          <Row className="between-sm start-sm">
            {bottomLinks.map((linkGroup, i) => (
              <Col key={linkGroup.title} className="col-sm-6">
                <input
                  type="checkbox"
                  id={`show-more-${i}`}
                  className={`show-more-${i}`}
                  hidden
                  aria-hidden
                />

                <Paragraph title={linkGroup.title} size="4" />

                <List className={`${styles['link-group']} link-group`}>
                  {linkGroup.links.map((item) => (
                    <ListItem key={item.text} className={item.className}>
                      <TextLink
                        className={`${styles['text-link__gray']} text-link__gray`}
                        text={item.text}
                        url={item.url}
                      />
                    </ListItem>
                  ))}
                </List>

                <label
                  htmlFor={`show-more-${i}`}
                  className="button clear full-width"
                  aria-hidden="true"
                >
                  <span className="more" hidden>
                    Ver mais
                  </span>
                  <span className="less">Ver menos</span>
                </label>
              </Col>
            ))}
          </Row>
        </Banner>
      </main>

      <Footer data={footerContent} />

      <script
        id="home-configs"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(structuredData),
        }}
      />
    </>
  );
};
