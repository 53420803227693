import { useEffect } from 'react';

import Image from 'next/image';
import dynamic from 'next/dynamic';

import {
  Footer,
  Row,
  Col,
  Button,
  Paragraph,
  StoreButton,
  Container,
} from 'darrius';

import { getBlurDataURL, imgPrefix, triggerEvent } from '../../utils';
import { getFlashTitle } from '../../utils/getFlashTitle';

import { HeadContent, Header, Toast } from '../../components';

import styles from './RequestTemplate.module.scss';

import { type IRequestTemplateParams } from './@interfaces';
import { type IUserCliDataResponse } from '../../modules/user';
import { type TRequestInfo } from '../../modules/postRequest/pageProps/@interfaces';

interface IRequestTemplateProps extends IRequestTemplateParams {
  userData: IUserCliDataResponse;
}

const ServiceExpressDialog = dynamic(
  () => import('./ServiceExpressDialog/ServiceExpressDialog'),
  {
    ssr: false,
  }
);

const DOWNLOAD_APP_LINK =
  'https://api-mobile.getninjas.com.br/api/v1/conversions/redirect_to_download_page/cli?utm_source=pos-request&utm_medium=getninjas&utm_campaign=cli_teste_ab_CLA_1001&utm_content=b';

const SERVICES = [
  {
    name: 'Assistência Técnica',
    slug: 'reformas-e-reparos/assistencia-tecnica',
  },
  { name: 'Diarista', slug: 'familia/diarista' },
  { name: 'Professor', slug: 'aulas' },
];

const FRAUD_TEXT =
  "⚠️ <strong>Não caia em golpes! <a class='no-fraud-link text-link' href='https://www.getninjas.com.br/seguranca'target='_blank'>Acesse aqui</a> nossas instruções de segurança.</strong>";

export const RequestTemplate = (props: IRequestTemplateProps) => {
  const {
    footerContent,
    meta,
    slug = '',
    hero,
    relatedCategories = null,
    request = {},
    userData,
  } = props;

  const { isServiceExpress = false } = request as TRequestInfo;

  const flashTitle = getFlashTitle(userData.notificationCount);

  useEffect(() => {
    const trackingTimeout = setTimeout(() => {
      triggerEvent('chat__nav-message', {
        userId: userData?.id,
      });
    }, 5000);
    return () => clearTimeout(trackingTimeout);
  }, [userData]);

  return (
    <>
      <div data-category-slug={slug}></div>

      <HeadContent
        flashTitle={flashTitle}
        metaTitle={meta.title}
        metaDescription={meta.description}
        pageType={meta.pageType}
        keywords={meta.keywords}
        noindex
      />

      <Header.Auth userData={userData} />

      <Container className={styles.container}>
        <div className={`${styles.section}`}>
          <Paragraph
            title="Agora falta pouco!"
            headline
            size="1"
            className={`${styles.title} no-margin`}
          />

          <Paragraph
            title="Baixe o app e valide seu pedido."
            headline
            size="2"
            className={`${styles.subtitle} no-margin`}
          />

          <Image
            src={imgPrefix('/images/ab-test/post-request/banner.svg')}
            alt={hero.heroImage.alt}
            title={hero.heroImage.alt}
            width={430}
            height={283}
            priority
            className={`responsive-image ${styles.image}`}
          />

          <div className={styles.status}>
            <Paragraph
              title="Como está o seu pedido:"
              size="3"
              className={styles['hows-your-request']}
            />

            <div>
              {hero.items.map(({ text, image }) => (
                <Row key={text} className={`start-sm top-sm ${styles.step}`}>
                  <Image
                    src={imgPrefix(image.replace('images', 'images/ab-test'))}
                    alt={text}
                    width={24}
                    height={60}
                  />

                  <span>{text}</span>
                </Row>
              ))}
            </div>

            <Paragraph
              text={FRAUD_TEXT}
              className={`${styles.warning} hide-md no-margin`}
              headline
            />
          </div>

          <Row className="center-sm hide-md post-request-hero__button">
            <Button.Link href={DOWNLOAD_APP_LINK}>Baixar o App</Button.Link>
          </Row>
        </div>

        <div className={`hide-sm ${styles['qrcode-area']}`}>
          <div className={styles['qr-code-text']}>{hero.qrcode.text}</div>

          <Image
            src={imgPrefix('/images/ab-test/post-request/b.svg')}
            alt={hero.qrcode.image.alt}
            title={hero.qrcode.image.alt}
            width={195}
            height={195}
            placeholder="blur"
            blurDataURL={getBlurDataURL()}
            priority
          />

          <Paragraph
            text="Baixe o app para acompanhar <br> o seu pedido e falar com profissionais!"
            headline
            size="2"
            className={`no-margin ${styles['download-app']}`}
          />

          <Row>
            <Col className="col-sm-6">
              <StoreButton store="playstore" size="small" />
            </Col>
            <Col className="col-sm-6">
              <StoreButton store="appstore" size="small" />
            </Col>
          </Row>

          <Paragraph
            text={FRAUD_TEXT}
            className={`${styles.warning} hide-sm`}
            aria-hidden="true"
          />
        </div>
      </Container>

      {relatedCategories && (
        <div>
          <Paragraph
            title="Também pode te interessar"
            size="2"
            className={`${styles['might-interest']} no-margin`}
            headline
          />

          <Row
            className={`center-sm row--slider-sm ${styles['most-requested-services']}`}
          >
            {SERVICES.map((service, i) => (
              <a
                href={`/${service.slug}`}
                key={service.name}
                className={styles.service}
              >
                <Image
                  src={imgPrefix(`/images/ab-test/post-request/${i}.jpg`)}
                  alt={service.name}
                  width={250}
                  height={250}
                  placeholder="blur"
                  blurDataURL={getBlurDataURL()}
                  quality={100}
                  style={{
                    objectFit: 'contain',
                  }}
                />

                <div className={styles.service__details}>
                  <span className={styles.service__name}>{service.name}</span>

                  <span className={styles.service__hire}>Contrate agora</span>
                </div>
              </a>
            ))}
          </Row>
        </div>
      )}

      {isServiceExpress && (
        <>
          <ServiceExpressDialog request={request as TRequestInfo} />
          <Toast />
        </>
      )}

      <Footer data={footerContent} />
    </>
  );
};
