import {
  Avatar,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from 'darrius';

// TODO: Comentando as duas linhas abaixo nessa primeira versão por não fazer sentido visto que não temos a página de detalhes do pedido
// import Link from 'next/link';
// import { MdKeyboardArrowRight } from 'react-icons/md';

import { Status } from '../../../../components';

import { getInfoToAuxiliaryPages } from '../../../../modules/orders/pageProps/getInfoToAuxiliaryPages/getInfoToAuxiliaryPages';

import { type IOrdersPageProps } from '../../../../modules/orders/pageProps/@interfaces';

import styles from './Orders.module.scss';

interface IOrdersProps {
  order: IOrdersPageProps;
}

export function Orders({ order }: IOrdersProps) {
  const hasAuxiliaryInfoPage = order.status == 'finalized_zero_lead' || order.status === 'blocked';

  return (
    <Container as="li" className={styles.orders}>
      <Row className="start-md start-sm">
        <Col className="col-sm-12 col-md-12">
          <time dateTime={order.createdAt} className={styles.orders__date}>
            {order.orderDate}
          </time>

          <Card className={styles.orders__card}>
            <CardHeader>
              <div className={styles.orders__card_header}>
                <div className={styles.orders__card_header__info}>
                  <span>{order.rootCategory}</span>
                  <h3>{order.category}</h3>
                </div>

                {/* TODO: Comentando esse código nessa primeira versão por não fazer sentido visto
                    que não temos a página de detalhes do pedido
                */}
                {/* <Link
                  href={'/nao-encontrado'} // TODO: Alterar para link da página de detalhes do pedido
                >
                  <MdKeyboardArrowRight size={24} />
                </Link> */}
              </div>

              <hr className={styles.orders__card_header__line} />
            </CardHeader>

            <CardBody className={styles.orders__card_body}>
              <Status.Root>
                <Status.Title
                  status={order.status}
                  remainingHours={order.remainingHours}
                  as="h3"
                />
                <Status.Description
                  status={order.status}
                  totalProfessionalsFound={order.totalProfessionalsFound}
                />
              </Status.Root>

              {order.leads.length > 0 && (
                <ul className={styles.orders__profiles}>
                  {order.leads.map((lead) => (
                    <li key={lead.id}>
                      <Avatar
                        src={lead.avatarUrl}
                        alt=""
                        size={58}
                        fallback={{
                          variant: 'byName',
                          name: lead.name,
                        }}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </CardBody>

            {!!hasAuxiliaryInfoPage && (
              <CardFooter>
                <hr className={styles.orders__card_footer__line} />
                <Button.Link href={getInfoToAuxiliaryPages(order.status).href}>
                  {getInfoToAuxiliaryPages(order.status).text}
                </Button.Link>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
