import { Avatar, Col, Row, Stars } from 'darrius';

import { triggerEvent } from '../../../utils';

import { FlatCard, FlatCardBody } from '../../../components';

import { type TPro } from '../../../modules/instantMatch/transformations';

import styles from './Pros.module.scss';
import { MdChevronRight } from 'react-icons/md';

type IProProps = Omit<TPro, 'servicePrice' | 'tag' | 'isInstitutionalPro'>;

export const Pro = (props: IProProps, index) => {
  const { id, link, name, photo, rating, isVerifiedPro, requestId } = props;
  return (
    <Col key={id} className={`col-sm-12 ${styles['pro-card']}`}>
      <FlatCard>
        <FlatCardBody>
          <a
            href={link}
            onClick={() =>
              triggerEvent('instant-match-profile-list__click-profile', {
                profile: id,
                requestId,
                index: index + 1,
              })
            }
            className="start-sm"
            aria-label={name}
          >
            <Row className={styles['pro-card__bio-section']}>
              <Col className="col-8">
                <div className={styles['pro-card__bio']}>
                  <Avatar
                    src={photo}
                    alt={name}
                    size={72}
                    fallback={{
                      name: name,
                      variant: 'byName',
                    }}
                  />

                  <div className={styles['pro-card__bio-info']}>
                    {isVerifiedPro ? (
                      <h3 className={styles['pro-card__name']}>
                        <span
                          className={styles['pro-card__name-nowrap']}
                        >{`${name}`}</span>
                      </h3>
                    ) : (
                      <h3>
                        <span
                          className={styles['pro-card__name-nowrap']}
                        >{`${name}`}</span>
                      </h3>
                    )}
                    <div
                      className={`${styles['pro-card__rating']} ${styles.rating__default}`}
                    >
                      <Stars rating={1} />

                      <p>{rating.value}</p>
                      <p
                        className={`${styles['pro-card__amount-rating']}`}
                      >{`(${rating.count})`}</p>
                    </div>
                  </div>
                </div>
              </Col>

              <Col className={`${styles['pro-card__pro-view']} col-4`}>
                <div className="hide-sm">
                  <p>Ver perfil</p>
                </div>
                <div className={styles['pro-card__arrow-icon']}>
                  <MdChevronRight size={24} />{' '}
                </div>
              </Col>
            </Row>
          </a>
        </FlatCardBody>
      </FlatCard>
    </Col>
  );
};
